import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Carousel } from "react-responsive-carousel";
import { ProductDetail } from "../utils/product";

class ArtistPage extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { artist } = pageContext;
    const artworks = data.allShopifyProduct.nodes;

    return (
      <Layout>
        <section className="container">
          <div className="card">
            <div className="card-content">
              <div className="media">
                <div className="media-left">
                  <figure className="image is-48x48">
                    <GatsbyImage
                      alt={artist.artist_name.text}
                      image={artist.artist_profile_picture.gatsbyImageData}
                    />
                  </figure>
                </div>
                <div className="media-content">
                  <p className="title is-4">{artist.artist_name.text}</p>
                  <p className="subtitle is-6">
                    {artist.artist_sub_header.text}
                  </p>
                </div>
              </div>
              <Carousel
                infiniteLoop={false}
                renderThumbs={(items) => {
                  return items.map((_, i) => {
                    return (
                      <figure
                        className="image is-clickable"
                        key={`thumbnail-${i}`}
                      >
                        <GatsbyImage
                          alt={artworks[i].title}
                          image={
                            artworks[i]?.media[0]?.preview?.image
                              .gatsbyImageData
                          }
                        />
                      </figure>
                    );
                  });
                }}
                showStatus={false}
                renderItem={(item) => {
                  const artwork = artworks.find((a) => a.id === item.key);
                  return (
                    <ProductDetail
                      product={artwork}
                      checkInventory={true}
                      key={`${artwork.handle}-detail`}
                    />
                  );
                }}
              >
                {artworks.map((a) => (
                  <div key={`${a.id}`}></div>
                ))}
              </Carousel>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const artworksQuery = graphql`
  query artworksQuery($name: String!, $tag: String!) {
    allShopifyProduct(filter: { tags: { eq: $tag }, vendor: { eq: $name } }) {
      nodes {
        id
        handle
        title
        descriptionHtml
        shopifyId
        variants {
          shopifyId
          price
          title
        }
        totalInventory
        media {
          preview {
            image {
              gatsbyImageData(layout: CONSTRAINED, width: 512)
            }
          }
        }
      }
    }
  }
`;

export default ArtistPage;
